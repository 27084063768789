import axios from "axios";
import qs from 'qs'
// 引入Vuex
import store from '@/store'
import {aesDecrypt, aesEncrypt} from "@/utils/encryption";
import website from "@/const/website";
import {getStore} from "@/utils/store";
// 全局 axios 默认值
// axios.defaults.baseURL = 'http://localhost:9999/api';
// axios.defaults.baseURL = 'https://fang.huzibaba.com/api';
axios.defaults.baseURL = '/api';
axios.defaults.headers.common['CLIENT-TOC'] = 'Y';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 返回其他状态吗
axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
// axios.defaults.withCredentials = true
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    console.log("aaaa")
    // 在发送请求之前做些什么
    const TENANT_ID = getStore({ name: 'tenantId' })
    const isToken = (config.headers || {}).isToken === false
    const token = store.getters.access_token
    if (token && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + token// token
    }
    if (TENANT_ID) {
        config.headers['TENANT-ID'] = TENANT_ID // 租户ID
    }
    // headers中配置serialize为true开启序列化
    if (config.method === 'get') {
        config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }
    // 请求报文加密
    if (config.headers['Enc-Flag']) {
        config.data = {
            encryption: aesEncrypt(JSON.stringify(config.data), website.encPassword)
        }
    }
    return config;
}, function (error) {
    console.log("aaaaaaaaa")
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    console.log("response")
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // 针对密文返回解密
    // 后台定义 424 针对令牌过去的特殊响应码
    if (response.status == 424) {
        console.log("response.status 424")
        store.dispatch("LogOut").then(()=> {
            store.commit('SET_SHOW_LOGIN', true)
        })
        return
    }
    if (response.data.encryption) {
        const originData = JSON.parse(aesDecrypt(response.data.encryption, website.encPassword))
        response.data = originData
        return response
    }
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default axios;
